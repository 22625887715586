export { getSiteId };
export { animation };

function animation() {
  let currentSite = window.sessionStorage.getItem("currentSite");
  let szenesScrollAdd = window.sessionStorage.getItem("szene1");
  let scrollAdVisited = window.sessionStorage.getItem("scollAdvise");
  let statusS1 = window.sessionStorage.getItem("szene1");
  let scrollAdviseEl = document.getElementById("scroll-advise");
  let sectionsEl = document.getElementById("sections");
  let tapAdvise = document.getElementById("tap-advise");
  
  //scroll advise
  if (currentSite === "intro") {
    let delayTime = 5000;
    scrollAdvise(delayTime);
  } else if (currentSite === "szenes" && szenesScrollAdd === "true" && scrollAdVisited === null) {
    let delayTime = 2000;
    scrollAdvise(delayTime);
    sectionsEl.addEventListener("scroll", removeScrollAdviseSzenes);
  } else {
    scrollAdviseEl.style.display = "none";
  }

  sectionsEl.addEventListener("scroll", removeScrollAdvise);
  
  function scrollAdvise(delayTime) {
    setTimeout(() => {
      scrollAdviseEl.style.display = "inherit";
    }, delayTime);
  }

  function removeScrollAdvise() {
    let scrollPos = sectionsEl.scrollTop;
    let scrollLimit = window.innerHeight * 1.5;
    if (scrollPos >= scrollLimit) {
      scrollAdviseEl.style.display = "none";
    }
  }
  
  function removeScrollAdviseSzenes() {
    scrollAdviseEl.style.display = "none";
    window.sessionStorage.setItem("scollAdvise", true);
  }
  
  //tap advise
  if (statusS1 === "true") {
    removeTapAdvise();
  } else if (currentSite === "szenes") {
    sectionsEl.addEventListener("scroll", removeTapAdvise);
    sectionsEl.addEventListener("scroll", removeScrollAdviseSzenes);
  }

  function removeTapAdvise() {
    tapAdvise.style.display = "none";
  }
}

//Set sessionStorage
let getSiteId = (site, siteValue) => {
  window.sessionStorage.setItem(site, siteValue);
};